// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/
import { addPropertyControls, ControlType } from "framer"
import React from "react"
import moment from "moment"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function CountdownTimer({
    date,
    showSeconds,
    direction,
    gap,
    background,
    blur,
    border,
    radius,
    padding,
    typography,
    translations,
}) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/
    const [timer, setTimer] = React.useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    // Styles are written in object syntax
    // Learn more: https://reactjs.org/docs/dom-elements.html#style
    const containerStyle: React.CSSProperties = {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: `${
            direction === "horizontal"
                ? `repeat(${showSeconds ? "4" : "3"}, 1fr)`
                : `1fr`
        }`,
        gridTemplateRows: `${
            direction === "horizontal"
                ? `1fr`
                : `repeat(${showSeconds ? "4" : "3"}, 1fr)`
        }`,
        gap: gap,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    }

    const itemStyle: React.CSSProperties = {
        font: `${typography.weight} ${typography.size}px/1 ${typography.font}`,
        color: typography.color,
        letterSpacing: typography.letter,
        lineHeight: typography.line,
        textTransform: typography.transform,
        padding: `${padding.y}px ${padding.x}px`,
        border: `${border.width}px ${border.style} ${border.color}`,
        borderRadius: `${radius}px`,
        width: "100%",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        background: background,
        backdropFilter: `blur(${blur}px)`,
    }

    const itemLabelStyle: React.CSSProperties = {
        marginTop: 8,
        fontSize: 12,
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            const then = moment(date)
            const now = moment()
            const countdown = moment.duration(then.diff(now))
            const days = then.diff(now, "days")
            const hours = countdown.hours()
            const minutes = countdown.minutes()
            const seconds = countdown.seconds()

            setTimer({ days, hours, minutes, seconds })
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div style={containerStyle}>
            <div style={itemStyle}>
                <div style={{ fontVariantNumeric: "tabular-nums" }}>
                    {timer.days.toString().padStart(2, "0") ?? 0}
                </div>
                <div style={itemLabelStyle}>{translations.days}</div>
            </div>
            <div style={itemStyle}>
                <div style={{ fontVariantNumeric: "tabular-nums" }}>
                    {timer.hours.toString().padStart(2, "0") ?? 0}
                </div>
                <div style={itemLabelStyle}>{translations.hours}</div>
            </div>
            <div style={itemStyle}>
                <div style={{ fontVariantNumeric: "tabular-nums" }}>
                    {timer.minutes.toString().padStart(2, "0") ?? 0}
                </div>
                <div style={itemLabelStyle}>{translations.minutes}</div>
            </div>
            {showSeconds && (
                <div style={itemStyle}>
                    <div style={{ fontVariantNumeric: "tabular-nums" }}>
                        {timer.seconds.toString().padStart(2, "0") ?? 0}
                    </div>
                    <div style={itemLabelStyle}>{translations.seconds}</div>
                </div>
            )}
        </div>
    )
}

addPropertyControls(CountdownTimer, {
    date: {
        type: ControlType.Date,
        defaultValue: "2024-04-25T00:00:00.000Z",
    },
    showSeconds: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    direction: {
        type: ControlType.Enum,
        defaultValue: "horizontal",
        options: ["horizontal", "vertical"],
        optionTitles: ["Horizontal", "Vertical"],
    },
    gap: {
        type: ControlType.Number,
        defaultValue: 16,
        displayStepper: true,
        unit: "px",
    },
    background: {
        type: ControlType.Color,
        defaultValue: "#000000",
    },
    blur: {
        type: ControlType.Number,
        title: "Bg Blur",
        defaultValue: 0,
        displayStepper: true,
        unit: "px",
    },
    border: {
        type: ControlType.Object,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#000000",
            },
            width: {
                type: ControlType.Number,
                defaultValue: 1,
                displayStepper: true,
                unit: "px",
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
    },
    radius: {
        type: ControlType.Number,
        defaultValue: 0,
        displayStepper: true,
        unit: "px",
    },
    padding: {
        type: ControlType.Object,
        controls: {
            y: {
                type: ControlType.Number,
                defaultValue: 32,
                displayStepper: true,
                unit: "px",
            },
            x: {
                type: ControlType.Number,
                defaultValue: 24,
                displayStepper: true,
                unit: "px",
            },
        },
    },
    typography: {
        type: ControlType.Object,
        controls: {
            font: { type: ControlType.String, defaultValue: "Inter" },
            weight: {
                type: ControlType.Enum,
                defaultValue: "400",
                options: [
                    "200",
                    "italic 200",
                    "300",
                    "italic 300",
                    "400",
                    "italic 400",
                    "500",
                    "italic 500",
                    "600",
                    "italic 600",
                    "700",
                    "italic 700",
                    "800",
                    "italic 800",
                ],
                optionTitles: [
                    "Extra Light",
                    "Extra Light Italic",
                    "Light",
                    "Light Italic",
                    "Regular",
                    "Italic",
                    "Medium",
                    "Medium Italic",
                    "Semibold",
                    "Semibold Italic",
                    "Bold",
                    "Bold Italic",
                    "Extra Bold",
                    "Extra Bold Italic",
                ],
            },
            color: {
                title: "Color",
                type: ControlType.Color,
            },
            size: {
                type: ControlType.Number,
                defaultValue: 16,
                unit: "px",
                step: 1,
                displayStepper: true,
            },
            letter: {
                type: ControlType.Number,
                defaultValue: 0,
                max: 10,
                unit: "%",
                step: 1,
                displayStepper: true,
            },
            line: {
                type: ControlType.Number,
                defaultValue: 1,
                max: 3,
                unit: "em",
                step: 0.1,
                displayStepper: true,
            },
            transform: {
                type: ControlType.Enum,
                defaultValue: "none",
                options: ["none", "capitalize", "uppercase", "lowercase"],
                optionTitles: ["None", "Capitalize", "Uppercase", "Lowercase"],
            },
        },
    },
    translations: {
        type: ControlType.Object,
        controls: {
            days: {
                type: ControlType.String,
                defaultValue: "day",
            },
            hours: {
                type: ControlType.String,
                defaultValue: "hou",
            },
            minutes: {
                type: ControlType.String,
                defaultValue: "min",
            },
            seconds: {
                type: ControlType.String,
                defaultValue: "sec",
            },
        },
    },
})
